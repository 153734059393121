<script>
import axios from 'axios'
import Layout from "../layouts/main";
import PageHeader from "@/components/page-header";
import { CurrencyDirective } from "vue-currency-input";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import fileDownload from 'js-file-download';

/**
 * transaction Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect
  },
  data() {
    return {
      title: "Balance",
      submitted: false,
      showmodal: false,
      historyLog:[],
      f_name:'',
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      startDate:0,
      endDate:0,
      filterOn: [],
      data:[],
      banks:[],
      bankid:'',
      document: null,
      nominal:'',
      isDisabled: false,
      fields: [
        { key: "create_at", label:'Date' },
        { key: "activity", label:'Activity' },
        { key: "debit", label:"Debit" },
        { key: "kredit", label:"Kredit" },
        { key: "balance", label:"Last Balance" }
      ],
      isBusy: false,
    };
  },
  validations: {
    bankid: {
      required,
    },
  },
  computed: {
    rows() {
      return this.historyLog.length;
    }
  },
  mounted() {
    this.totalRows = this.historyLog.length;
  },
   directives: {
    currency: CurrencyDirective,
  },
  methods: {
    onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
    },
    logBalance(){
      let param = `0/0`;
      if (this.startDate && this.endDate) {
        param = `${this.startDate}/${this.endDate}`;
      }
      let id = JSON.parse(localStorage.getItem("user")).user_id;
      axios.get(`v1/historybal/${id}/${param}`)
      .then((res) => {
        this.historyLog = res.data.user
      })
    },
    onChangebank(){
      this.nominal = ''
      this.bankid = ''
    },
    handleSubmit(){
      this.$v.bankid.$touch();
      this.submitted = true;
      this.isDisabled = true;
      if(!this.$v.bankid.$invalid){
        let userID = JSON.parse(localStorage.getItem("user")).user_id;
        let name = JSON.parse(localStorage.getItem("user")).first_name + ' ' + JSON.parse(localStorage.getItem("user")).last_name;
        let amount = this.remove_format(this.nominal)
        let data = new FormData()
        data.append('name', name);
        data.append('nominal', amount);
        data.append('bank_id', this.bankid.id_bank);
        data.append('document', this.document);
        
        axios.post(`/v1/top-up/${userID}`,data)
        .then((res) => {
         if(res.data.success) {
            Swal.fire("Success", "Your request is being processing", "success");
            this.getNotif();
            this.showmodal = false;
            this.isDisabled = false;
            this.submitted = false;
          } else {
            Swal.fire("Failed ", res.data.message , "error");
            this.isDisabled = false;
            this.submitted = false;
          }
        })
      } else {
        Swal.fire("Warning", "Select Required", "error");
        this.submitted = false;
        this.isDisabled = false;
      }
    },  
    getBank(){
      this.isBusy = true;
        axios.get(`v1/bank-aff`)
        .then((res) => {
          this.isBusy = false;
          this.banks = res.data.bank;
        })
    },
    hideModal() {
        this.nominal = '',
        this.bankid = '',
        this.document = '',
        this.showmodal = false;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatPriceRP(value) {
      let val = (value / 1).toFixed(0).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatPriceVND(value) {
      let val = (value / 1).toFixed(3).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    onChangeDocument(e){
      this.document = e.target.files[0];
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
      console.log(evt)
    },
    remove_format(money) {
      return money.toString().replace(/[^0-9]/g, "");
    },
    download(){
      let id = JSON.parse(localStorage.getItem("user")).user_id;
      let param =''
      if(this.startDate !== 0) {
        param = `_${this.startDate}_${this.endDate}`
      }
      axios({
        url: `/v1/download/balance/${id}/${this.startDate}/${this.endDate}`,
        method: 'GET',
        responseType: 'blob'
      }).then((res) => {
        fileDownload(res.data, `Balance${param}.Xlsx`)
      }).catch((err) => {
        console.log(err)
      })
    },
  },
  created () {
      this.logBalance();
      this.getBank();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div>
              <a href="javascript:void(0);" class="btn btn-primary mb-2" @click="showmodal = true"  >
                <i class="mdi mdi-plus mr-2"></i> TOP UP
              </a>
              <a href="javascript:void(0);" class="btn btn-primary mb-2" style="float:right" @click="download">
                <i class="mdi mdi-download mr-2"></i> Download
              </a>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="">
                  <div class="card-body">
                    <div class="row mt-4">
                      <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_length" class="dataTables_length">
                          <label class="d-inline-flex align-items-center">
                            Show&nbsp;
                            <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                          </label>
                          <div>
                            <label class="d-inline-flex align-items-center">
                            Date 
                            <b-form-input class="form-control form-control-sm ml-3" v-model="startDate" type="date" icon="">
                            </b-form-input>
                            <b-form-input class="form-control form-control-sm" v-model="endDate" type="date" icon="">
                            </b-form-input>
                            <b-button variant="info" class="form-control form-control-sm py-1" @click="logBalance"
                              >Submit</b-button>
                          </label>
                          </div>
                        </div>
                      </div>
                      <!-- Search -->
                      <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                          <label class="d-inline-flex align-items-center">
                            Search:
                            <b-form-input
                              v-model="filter"
                              type="search"
                              placeholder="Search..."
                              class="form-control form-control-sm ml-2"
                            ></b-form-input>
                          </label>
                        </div>
                      </div>
                      <!-- End search -->
                    </div>
                    <!-- Table -->
                    <div class="table-responsive mb-0">
                      <b-table
                        :items="historyLog"
                        :fields="fields"
                        striped
                        responsive="sm"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        :busy="isBusy"
                        show-empty
                      >
                      <template v-slot:cell(debit)="row">
                        <div style="float:right"
                        >{{ row.value != 0 ? formatPriceRP(row.value) : '-'}}</div>
                      </template>
                      <template v-slot:cell(kredit)="row">
                        <div style="float:right"
                        >{{ row.value != 0 ? formatPriceRP(row.value) : '-'}}</div>
                      </template>
                      <template v-slot:cell(balance)="row">
                        <div style="float:right"
                        >{{ formatPriceRP(row.value)}}</div>
                      </template>
                      <template #table-busy>
                        <div class="text-center text-danger my-2">
                          <b-spinner class="align-middle mr-2"></b-spinner>
                          <strong>Loading...</strong>
                        </div>
                      </template>
                      <template #empty>
                        <h5 class='empty'>No History Balance ...</h5>
                      </template>
                      </b-table>
                    </div>
                    <!-- <template v-else>
                      <h5 class='empty'>No Data ...</h5>
                    </template> -->
                    <div class="row">
                      <div class="col">
                        <div class="dataTables_paginate paging_simple_numbers float-right">
                          <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <b-modal
      id="modal-2"
      v-model="showmodal"
      title="TOP UP"
      title-class="text-dark font-18"
      scrollable
      hide-footer
      centered
      size="lg"
      @show="onChangebank"
      @hidden="onChangebank"
    >
      <form @submit.prevent="handleSubmit">
        <template>
          <div class="card">
            <div class="card-body" style="min-height:20rem">
              <div class="row mb-2">
                <div class="col-4 mt-2">
                  <h5 for="topup">Amount TOP UP</h5>
                </div>
                <div class="col-8">
                  <input
                    id="topup"
                    v-model="nominal"
                    type="text"
                    class="form-control"  
                    placeholder="Enter Amount TOP UP"
                    required
                    autocomplete="off"
                    @keypress="isNumber"
                    v-currency="{
                      locale: 'default',
                      currency: null,
                      precision: 0,
                      distractionFree: false,
                      autoDecimalMode: false,
                      valueRange: { min: 0 },
                      allowNegative: false,
                    }"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-4 mt-2">
                  <h5>Select Bank Payment</h5>
                </div>
                <div class="col-8">
                  <multiselect
                    v-model="bankid" 
                    :options="banks" 
                    label="bank_name"
                    :selectLabel="null"
                    :deselectLabel="null"
                    :allow-empty="false"
                    placeholder="Select Bank Payment"
                    required
                  >
                  </multiselect>
                  <small
                    class="mb-0 text-danger"
                    v-if="
                      !$v.bankid.required &&
                      $v.bankid.$error
                    "
                  >
                    Select Bank Payment is required
                  </small>
                </div>
              </div>
              <div class="row" v-if="bankid">
                <div class="col-8 offset-4">
                  <div class="card bank-details">
                    <div class="row">
                      <div class="col-4">
                        <p>Account Holder</p>
                        <p>Account Number</p>
                        <p>Account Bank</p>
                      </div>
                      <div class="col">
                        <p>{{bankid.acc_name}}</p>
                        <p>{{bankid.no_rek}}</p>
                        <p>{{bankid.bank_name}}</p>
                      </div>
                    </div>
                  </div>
                  <small class="info-text">*Please pay with the registered account bank name</small>
                </div>
              </div>
              <div class="row">
                <div class="col-4 mt-3">
                  <h5>Upload Invoice</h5>
                </div>
                <div class="col-8 mt-2">
                  <input
                    type="file"
                    class="form-control"
                    @input="onChangeDocument"
                    placeholder="Upload Invoice Transfer"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="text-right">
            <b-button variant="secondary" @click="hideModal">Cancel</b-button>
            <button type="submit" class="btn btn-success ml-1" style="width:5rem" :disabled="isDisabled">
              <span v-if="submitted"><b-spinner class="p-0" style="width:20px;height:20px" variant="black" role="status"></b-spinner></span>
              <span v-else>Submit</span>
            </button>
          </div>
        </template>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>